<template>
  <div>
    <Footer></Footer>
      <div class="content">
        
        <span class="title">
          “大唐杯”全国大学生新一代信息通信技术大赛赛区划分
          <p class="titext">
            <span>“大唐杯”组委会</span>
            &emsp;&emsp;&emsp;&emsp;&emsp;
            <span>发布于2022-06-19</span>
          </p>
        </span>

        <div class="imgbox">
          <!-- <img  style="width:100%;height: 100%;" src="./Framework_img/img_A.jpg" alt="">  -->
        </div>

        <div class="contbox">
          <p class="text"> 大唐杯赛事</p>
          <p class="text_t">大唐杯”全国大学生新一代信息通信技术大赛是工信部人才交流中心与中国通信企业协会联合主办，中信科移动通信技术股份有限公司、大唐移动通信设备有限公司、北京市教委北京高校电子信息类专业群共同组织承办，北方工业大学、北京邮电大学、武汉职业技术学院等高校协办的全国性电子信息类相关专业创新实践型的竞赛。大赛面向高技能人才培养，面向高质量专业建设，面向科技创新发展，面向产业生态培育，服务于社会经济发展。“大唐杯”践行以赛促学、以赛促教、以赛促练、以赛促建、以赛促改的办赛理念，面向在校大学生和信息通信工程实践教师，为全国新一代信息通信产业发展提供实践型、创新型、复合型人才培养平台。大赛将专业与产业、教学与岗位、课程与生产、教材与项目进行对齐；将竞赛环境、育人平台、教学体系、评估机制作为人才培养模式的创新方向；以专业教学与产业工程、传统学科与新兴学科、育人机制与项目管理、人才培养与社会服务深度融合为目的。</p>
          <!-- <p class="text_title"> 2.大赛组委会职责：</p> -->
          <p class="text_t">“大唐杯”设立省份选拔赛和全国总决赛两个赛程。面向双一流本科院校，普通本科院校，职业院校，一带一路国际合作院校分别设置研究生、本科、高职、一带一路等不同赛道组别。为提升“大唐杯”办赛质量和执行效能，大赛按照南北区域进行组织划分，并成立赛区委员会（以下简称区委会）。</p>
          <p class="text_t">大赛组委会设置在工业和信息化部人才交流中心，负责全国范围内的大赛统筹和监督工作。根据赛事规模，大赛组委会设立指导委员会，指导委员会下设区委会，区委会在组委会及指导委员会的指导下开展工作。2022年度区委会由北部赛区区委会和南部赛区区委会组成。各赛区委员会设置区委会主任委员和区委会专家委员岗位角色，履行区委会职责。</p>
          <p class="text_t"></p>

          <p class="text">区委会组织架构图</p>
          <p class="text_img" ><img style="width:100%" src="./fingerpost_img/LiuC.png" alt=""></p>
          <p class="text_title"> 区委会主要职责包括</p>
          <p class="text_t">（1）负责传达、落实大赛组委会工作精神和要求；</p>
          <p class="text_t">（2）负责赛区整体赛事的指导、组织、管理、协调工作；</p>
          <p class="text_t">（3）负责赛区各组织机构的组建、调整工作；</p>
          <p class="text_t">（4）落实大赛组委会其他工作事项。 </p>
          <p class="text_title"> 区委会主任委员遴选原则</p>
          <p class="text_t">（1）热爱祖国,治学严谨,遵守学术规范,具有较强的事业心和团队精神，具有深厚的教书育人情怀。</p>
          <p class="text_t">（2）具有参与赛区工作的主动意愿，担任过同等级别竞赛的组委会、秘书处、裁判员的人员优先。</p>
          <p class="text_t">（3）国内教育教学领域和工业信息化领域权威指导机构的专家优先。</p>
          <p class="text_t">（4）电子信息类专业尤其信息通信专业领域的教学名师、信息通信产业内的高级专家优先。</p>

          <p class="text_title"> 区委会专家委员遴选原则</p>
          <p class="text_t">（1）热爱祖国,治学严谨,遵守学术规范,具有较强的事业心和团队精神，具有深厚的教书育人情怀。</p>
          <p class="text_t">（2）能够积极推广宣传大赛，具有多年校企合作经验，能够组织并承担本校参赛对接事宜。</p>
          <p class="text_t">（3）电子信息类专业尤其信息通信专业领域的教学名师、信息通信产业内的专家优先。</p>
          <p class="text_t" style="margin-left: 7rem; margin-top: .6rem;">2022年6月</p>

          <div class="img_box">
          </div>
        </div>
      </div>

  <Bottom></Bottom>
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
import $ from "jquery";
  export default {
    name: 'Home',
    components: {
        Footer,
        Bottom
    },
    data(){
      return {

      }
    },
    methods:{
    },
    mounted(){

    },
    created(){

    },
  }
  </script>
  
  <style scoped lang="scss">
  @import './fingerpost.scss';
  </style>
  